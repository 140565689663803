import { useState } from 'react';
import Interview from './Interview';
import "./CallScreen.css";
import robot from "./start-robot.png"
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ACCELEREC_API, ACCELEREC_FE } from './config';
import { ReactComponent as VideoLogo } from './video-solid.svg';

const RULES = [
  "Upon clicking “Start Interview,” candidates must allow camera and microphone access.",
  "Before beginning the interview, ensure your microphone and camera are functioning properly.",
  "After the interview starts, there will be a limited time to answer each question. The time allocated may vary depending on the nature of the question.",
  "If you cannot answer a question within the specified time, the next question will appear automatically.",
  "There is no option to retry any question.",
  "Candidates must answer a total of 10 questions.",
  "If you cannot complete an answer within the given time, the system will automatically evaluate based on your partial response."
];

function InterviewInitiateScreen() {

  const [callAccepted, setCallAccepted] = useState();
  const [devicePermission, setDevicePermission] = useState(false);
  const [questions, setQuestions] = useState({});
  const [message, setMessage] = useState([]);
  const [loading, setLoading] = useState(false)

  const { username } = useParams();
  async function getPermission(permission) {
    try {
      const result = await navigator.permissions.query({ name: permission });
      if(result.state === 'denied') {
        setMessage((m) => [...m, `Permission denied for ${permission}`]);
      } else if(result.state === 'prompt') {
        setMessage((m) => [...m, `Requesting Permission for ${permission}`]);
      } else if(result.state === 'granted') {
        setMessage(m => [...m, `Permission granted for ${permission}`]);
      }
    } catch (error) {
      console.log(`${permission} (not supported) - denied`);
      return false
    }
  }

  async function requestPermissions() {

  // check if the browser supports the permissions API
    if (!('permissions' in navigator) || !('mediaDevices' in navigator)) {
      console.log('browser not supported');
      setMessage('Browser not supported - denied')
      return
    }

    const permissions = [
      'camera',
      'microphone',
    ];
    for (const permission of permissions) {
      await getPermission(permission);
    }

    navigator.mediaDevices.getUserMedia({ video: true, audio: true, })
      .then((stream) => {
        stream.getTracks().forEach(track => track.stop());
        setDevicePermission(true);
        setCallAccepted(true);
        return stream;
      })
      .catch(() => {
        setDevicePermission(false);
      });
  }

  async function fetchQuestions() {
    try {
      const response = await axios.get(`${ACCELEREC_API}/api/elastic-search/record/${username}`)
      let unAnwseredQuestions = response.data[0]._source.questions_json
      if(response.data[0]._source.interview){
        unAnwseredQuestions = response.data[0]._source.questions_json.filter(q => !response.data[0]._source.interview.interview_qa.find(a => a.question === q.question))
      }
      response.data[0]._source.questions_json = unAnwseredQuestions
      setQuestions(response.data[0]._source)
      if(response.data[0]._source.interview?.evaluated){
        setMessage(m => [...m, "Interview already conducted - denied"])
      }
    } catch (error) {
      console.error(error)
      setMessage(m => [...m, 'Interview does not exist -  denied'])
    }

  }

  async function fetchInterviewDetails() {
    try {
      const response = await axios.get(`${ACCELEREC_API}/api/v2/candidate/candidate-by-applicantId/${username}`)
      const startDate = new Date(response.data.data.scheduledInterviewDateTime);
      const endDate = new Date(startDate.getTime() + 72 * 60 * 60 * 1000); // Add 72 hours
      const currentDate = new Date();
      const isInterviewCancelled = response.data.data.inviteStatus === 'REJECTED_BY_RECRUITER' || response.data.data.inviteStatus === 'REJECTED'
      const isExpired = currentDate >= endDate
      if(isExpired || isInterviewCancelled){
        setMessage(m => [...m, "Interview either cancelled or expired - denied"])
      }
    } catch (error) {
      console.error(error)
      setMessage(m => [...m, 'Interview does not exist -  denied'])
    }

  }

  console.log(callAccepted, devicePermission, questions.questions_json, Boolean(!questions.interview?.evaluated))
  if(callAccepted && devicePermission && questions.questions_json && !questions.interview?.evaluated && message.filter(m => m.includes("denied")).length === 0){
    return <Interview 
      endCall={() => {
        setCallAccepted(false)
        window.location.replace(ACCELEREC_FE)
      }} 
      questions={questions.questions_json} 
      name={questions.name}
    />
  }

  return (
    <div className='container'>
      <div className='initate-container' style={{ maxWidth: "fit-content" }}>
        <img src={robot} width={320} />
        <button 
          disabled={message.filter(m => m.includes("denied")).length > 0 || loading}
          className='initiate-button'
          onClick={async (e) => {
            if(e.detail == 1){
            setLoading(true)
            await fetchQuestions();
            await fetchInterviewDetails();
            await requestPermissions();
            setLoading(false)
          }
          }}
        ><span style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
          <VideoLogo width={25} />  Start Interview
          </span>
        </button>
        {message.map(message => {
          return <span style={{ marginBottom: "4px", color: message.includes("denied") ? "red" : "black" }}>{message}</span>
        })}
      </div>
      <div className='initate-container' style={{ width: "45%", alignSelf: "stretch", justifyContent: "space-between"}}>
        <strong style={{ fontSize: "16px", color: "#ff0000bd" }}>Please read the rules before starting the interview:</strong>
        <ul>
          {RULES.map((rule, index) => {
            return <li key={index} className='rule'>{rule}</li>
          })}
        </ul>
        <strong style={{ fontSize: "16px"}}>Thank you & best of luck for your interview!</strong>
      </div>
    </div>
  );
}

export default InterviewInitiateScreen;