import { RouterProvider, createBrowserRouter } from "react-router-dom";
import CallScreen from "./CallScreen";
import HomeScreen from "./HomeScreen";

function App() {
  return (
    <RouterProvider 
      router={createBrowserRouter([
        { path: "/", element: <HomeScreen /> },
        { path: "/call/:username/:room", element: <CallScreen /> }
      ])}
    />
  );
}

export default App;