import { useParams } from "react-router-dom";
import { useRef, useEffect, useState, useCallback } from "react";
import socketio from "socket.io-client";
import axios from "axios";
import RecordRTC from 'recordrtc';
import robot from "./robot.png"
import { ACCELEREC_API, ACCELEREC_FE, API_URL } from "./config";
import { ReactComponent as HourGlass} from "./HourGlass.svg";
import  { ReactComponent as Moon } from "./moon-solid.svg";
import  { ReactComponent as Sun } from "./sun-solid.svg";

const END_TEXT = "Thank you for your time. The interview is now over. Goodbye!";
const BEGIN_TEXT = "Hi, I am the interviewer. I will ask you a series of questions. You will have a limited time to answer each question. Timer will be shown. Please speak clearly and concisely. Let's begin!";
const NEXT_QUESTION_TEXT = "Next question is: ";

const socket = socketio(`${API_URL}`, {
  autoConnect: false
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


function Interview({ endCall, questions, name }) {
  const params = useParams();
  const localUsername = params.username;
  const roomName = params.room;
  const [timer, setTimer] = useState({
    time: 0,
    question: "",
    type: "BEGIN", // BEGIN, NEXT_QUESTION, END
    questionSerial: 0
  });
  const [isRecording, toggleRecording] = useState(false);
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem("dark-mode") == "true");
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const recorderRef = useRef(null);

  async function handleRecording() {
    console.log('recording', isRecording)
    if(isRecording){
      recorderRef.current.stopRecording(() => {
        let blob = recorderRef.current.getBlob();
        socket.emit('audio', { room: roomName, question: "sample", id: "test", audio : blob });
      });
     
    } else {
      recorderRef.current.startRecording();
    }
    toggleRecording(state => !state)
  }


  let pc; // For RTCPeerConnection Object
  let synth = window.speechSynthesis;
  let VOICE = synth.getVoices().find(voice => voice.name === "Google UK English");

  const sendData = (data) => {
    socket.emit("data", {
      username: localUsername,
      room: roomName,
      data: data,
    });
  };

  const startConnection = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      console.log("Local Stream found");
      localVideoRef.current.srcObject = stream;

      recorderRef.current= RecordRTC(stream, {
        type: 'audio'
      });

      socket.connect();
      socket.emit("join", { username: localUsername, room: roomName });
      return stream;
    } catch (error) {
      console.error("Error accessing media devices.", error);
    }
  };

  const onIceCandidate = (event) => {
    if (event.candidate) {
      console.log("Sending ICE candidate");
      sendData({
        type: "candidate",
        candidate: event.candidate,
      });
    }
  };


  const onTrack = async (event) => {
    console.log("Adding remote track");
    remoteVideoRef.current.srcObject = event.streams[0];
  };

  const createPeerConnection = () => {
    try {
      pc = new RTCPeerConnection({});
      pc.onicecandidate = onIceCandidate;
      pc.ontrack = onTrack;
      const localStream = localVideoRef.current.srcObject;
      for (const track of localStream.getTracks()) {
        pc.addTrack(track, localStream);
      }
      console.log("PeerConnection created");
    } catch (error) {
      console.error("PeerConnection failed: ", error);
    }
  };


  const setAndSendLocalDescription = (sessionDescription) => {
    pc.setLocalDescription(sessionDescription);
    console.log("Local description set");
    sendData(sessionDescription);
  };

  const sendOffer = () => {
    console.log("Sending offer");
    pc.createOffer().then(setAndSendLocalDescription, (error) => {
      console.error("Send offer failed: ", error);
    });
  };

  const sendAnswer = () => {
    console.log("Sending answer");
    pc.createAnswer().then(setAndSendLocalDescription, (error) => {
      console.error("Send answer failed: ", error);
    });
  };
  const signalingDataHandler = (data) => {
    if (data.type === "offer") {
      createPeerConnection();
      pc.setRemoteDescription(new RTCSessionDescription(data));
      sendAnswer();
    } else if (data.type === "answer") {
      pc.setRemoteDescription(new RTCSessionDescription(data));
    } else if (data.type === "candidate") {
      pc.addIceCandidate(new RTCIceCandidate(data.candidate));
    } else {
      console.log("Unknown Data");
    }
  };
  socket.on("ready", () => {
    console.log("Ready to Connect!");
    createPeerConnection();
    sendOffer();
  });

  socket.on("data", (data) => {
    console.log("Data received: ", data);
    signalingDataHandler(data);
  });

  // socket.on('audio-to-text', data =>{
  //   text[data.id] = data;
  //   setText({...text});
  // })

  async function evaluateAnswers(){
    return axios.post(`${ACCELEREC_API}/api/interview/evaluation`, { id: localUsername }).then(console.log)
  }

  async function moveToNextQuestion(source){
    
  }

  const startInterview = useCallback(async() => {
    let utterance = new SpeechSynthesisUtterance(BEGIN_TEXT);
    utterance.voice = VOICE;
    synth.speak(utterance);
    await sleep(BEGIN_TEXT.length * 75);
    for (let question of questions) {
      const qIndex = questions.indexOf(question);
    let nextQuestion = questions[qIndex];
    let utterance = new SpeechSynthesisUtterance(`${qIndex > 0 ? NEXT_QUESTION_TEXT : "First Qustion is: "} ${nextQuestion.question}`);
    utterance.voice = VOICE;
    synth.speak(utterance);
    await sleep(nextQuestion.question.length * 85);
    setTimer({
      time: 60,
      question: nextQuestion.question,
      type: "question",
      is_last_question: (qIndex + 1) == questions.length,
      questionSerial: qIndex + 1
    });
    // it will be null if the user leaves the page before the timer ends
    if(!recorderRef.current) return;
    recorderRef.current.startRecording();
    toggleRecording(true);
    await new Promise((resolve) => {
      let interval = setInterval(() => {
        setTimer((prev) => {
          return { ...prev, time: prev.time - 1 };
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        resolve();
      }, 60 * 1000);
    });
    if(!recorderRef.current) return;
    recorderRef.current.stopRecording(() => {
      let blob = recorderRef.current.getBlob();
      socket.emit('audio', { 
        username: localUsername, 
        room: roomName, 
        question: nextQuestion.question, 
        id: nextQuestion.id, 
        is_last_question: (qIndex + 1) == questions.length , 
        audio : blob 
      });
    })
    toggleRecording(false);
    synth.cancel();
    synth = window.speechSynthesis;
    }
    utterance = new SpeechSynthesisUtterance(END_TEXT);
    utterance.voice = VOICE;
    synth.speak(utterance);
    await sleep(END_TEXT.length * 75);
    setTimer({
      type: "END"
    })
    pc?.close();
    localVideoRef.current = null;
    endCall();
    // await evaluateAnswers();
  }, [])

  useEffect(() => {
    if(!darkMode)
      document.body.style.backgroundColor = "white";
    startInterview();
    let stream;
    startConnection().then(s => {
      stream = s;
    })
    return function cleanup() {
      pc?.close();
      socket.disconnect();
      synth.cancel()
      recorderRef.current = null;
      if(stream) stream.getTracks().forEach((track) => { track.stop(); });
    };
  }, []);

	return (
    <div style={{ 
      padding: "40px 80px",
    }}>
      <div style={{ display: "flex", justifyContent: "center", fontSize: "22px"}} className={`${darkMode ? "text-light" : "text-dark"}`}><strong>{name && `AI Interview with ${name}`}</strong></div>
      <div style={{ marginBottom: "10px", display: "flex", justifyContent: "end", alignItems: "center" }}>
      <div>
        <input type="checkbox" class="checkbox" id="checkbox" checked={!darkMode} onChange={() => {
          localStorage.setItem("dark-mode", !darkMode);
          if(darkMode){
            // switch to light mode
            document.body.style.backgroundColor = "white";
          } else {
            // switch to dark mode
            document.body.style.backgroundColor = "rgb(0 0 0 / 81%)"
          }
          setDarkMode(dMode => !dMode);
        }}/>
        <label for="checkbox" class="checkbox-label">
          <Moon width={15} />
          <Sun  width={15} />
          <span class="ball"></span>
        </label>
      </div>
      <div>
        <div 
          onClick={() => {
            window.location.replace(ACCELEREC_FE);
          }}
          className={darkMode ? "text-light border-light" : "text-dark border-dark"}
          style={{ 
            padding: "20px 25px",
            fontSize: "20px",
            lineHeight: "0px",
            cursor: "pointer",
          }}> <span>Exit Interview</span></div>
        </div>
      </div>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
      >
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "46%",
          alignSelf: "stretch",
          // opacity: 0.8,
          backgroundColor: "#F8FDFF",
          // borderRadius: "10px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          padding: "10px",
          textAlign: "center",
        }}>
            {timer.time === 0 && <div class="boxContainer">
              <div class="box box1"></div>
              <div class="box box2"></div>
              <div class="box box3"></div>
              <div class="box box4"></div>
              <div class="box box5"></div>
            </div>}
            <img src={robot} style={{ objectFit: "cover" }} width="45%" />
            {timer.time === 0 && <div class="boxContainer">
              <div class="box box1"></div>
              <div class="box box2"></div>
              <div class="box box3"></div>
              <div class="box box4"></div>
              <div class="box box5"></div>
            </div>}
        </div>
        <video style={{
          // borderRadius: "10px",
          width: "46%",
          objectFit: "cover",
          border: "3px solid #FF9933",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          transform: 'rotateY(180deg)'
        }} autoPlay muted playsInline ref={localVideoRef} />
      </div>
      <div style={{ display: "flex", marginLeft: "15px" }}>
        <p className={`typed-out ${darkMode ? "text-light" : "text-dark"}`} key={timer.questionSerial}>{timer.is_last_question && "Last Question: "} {timer.question}</p>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginLeft: "30px" }}>
        <HourGlass height={50} /> <span className={`typed-out ${darkMode ? "text-light" : "text-dark"}`}  >Time Left: <span className="orange-text">{timer.time}s </span></span>
      </div>
      {/* <button 
        className='initiate-button' 
        onClick={() => moveToNextQuestion("button")}
        disabled={timer.time === 0 || timer.is_last_question}>
          Move to Next Question
        </button> */}
    </div>
  );
}

export default Interview;